<template>
    <div>
        <van-nav-bar title="我的二维码" left-arrow  @click-left="onClickLeft"/>
        <div style="background-color: #f2f3f4;padding:20px">
            <div class="codeBox">
                <div  id='qrcode' style="margin:0 auto"></div>
            </div>
            <div class="infoContent">
                <div class="infoBox">
                    <p>卡类型： {{memberCardRespVo.memberType==2?'提货卡':'储值卡'}}</p>
                    <p>开卡日期： {{memberCardRespVo.confirmDate}}</p>
                    <p v-if="memberCardRespVo.memberType==2">商品： {{memberCardRespVo.applicableProduct}}</p>
                    <p v-if="memberCardRespVo.memberType==2">数量： {{memberCardRespVo.originCouponCount}}</p>
                    <p>金额： {{memberCardRespVo.originAmount}}</p>
                </div>
            </div>
       </div>
    </div>
</template>
<script>
import QRCode from "qrcodejs2";
import {getuserInfo}  from "@/api/base";
import { Notify } from 'vant';
import func from '@/lib/commonfun';
export default {
  name: 'mycode',
  data(){
    return {
      memeberInfo:{
        nickname:'',
        mobile:''
      },
      memberCardRespVo:{
        activationDate:''
      }
     
    }
  },
  mounted(){
    this.memeberInfo.cardNum=this.$route.query.cardNo;
    this.getuserinfoFun();
  },
  methods:{
    getuserinfoFun(){
      getuserInfo().then((res)=>{
        if(res.code==0){
          if(res.data){
            this.memeberInfo=res.data;
            this.memberCardRespVo=res.data.memberCardRespVo;
            this.memberCardRespVo.confirmDate=func.timestampToYMD(this.memberCardRespVo.confirmDate)
            this.qrCode(this.memeberInfo.memberCardRespVo.activationQrCode);
          }
        }else{
          Notify({ type: 'warning', message: res.msg });
        }
      },(err)=>{
        Notify({ type: 'warning', message: err.response.data.message});
      })
    },
    onClickLeft(){
      this.$router.go(-1)
    },
    qrCode(url){
        document.getElementById('qrcode').innerHTML = '';
        var qrcode=new QRCode('qrcode',{
            text:url,//要生成二维码的链接
            margin:15,
            quality:.5,
            width:300,//二维码的宽度
            height:300,//二维码的高度
            colorDark:'#000000',//前景色
            colorLight:'#ffffff',//背景色
            correctLevel:QRCode.CorrectLevel.H  //纠错等级
          })
          qrcode.clear() //清除二维码 
          qrcode.makeCode(url) //生成另一个新的二维码  
     },
  }
}
</script>
<style lang="scss" scoped>
  .codeBox{
      padding:20px;
      background: #fff;  
      border-radius: 10px; 
  }
  /deep/ #qrcode img{
    margin:0 auto !important;
  }
  .infoContent{
    background: #fff;
    border-radius: 10px;
    padding:20px;
    margin-top:40px;
  }
  .infoBox{
    border-left:2px solid #ac931b;
    padding:10px;
    background: #f9f9f9;
    p{
      color:#969799;
      line-height:40px;
      padding-left:10px;
    }
  }
</style>
